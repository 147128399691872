<template>
  <lab-task><lab-equilibrium :conc-h-cl="concHCl" /></lab-task>
</template>

<script>
import Vue from 'vue';
import LabTask from './labs/LabTask';
import LabEquilibrium from '../simulations/LabEquilibrium';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';

export default Vue.extend({
  name: 'Equilibrium',
  components: {LabEquilibrium, LabTask},
  mixins: [DynamicQuestionMixin()],
  computed: {
    concHCl() {
      return this.taskState.getValueBySymbol('concHCl').content.toFloat();
    },
  },
});
</script>
