









import Vue, {PropOptions} from 'vue';
import LabSim from '@/tasks/components/simulations/LabSim.vue';

export interface IEquilibriumInput {
  concHCl: number; // Concentration of HCl
}

export default Vue.extend({
  name: 'LabEquilibrium',
  components: {LabSim},
  inheritAttrs: false,
  props: {
    concHCl: {
      type: Number,
      default: 3.0,
    } as PropOptions<number>,
  },
  computed: {
    labData(): IEquilibriumInput {
      return {concHCl: this.concHCl};
    },
    stringifiedLabData(): string {
      return JSON.stringify(this.labData);
    },
  },
});
